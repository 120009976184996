<template>
  <div class="MyMenu">
    <div class="head">
      <van-nav-bar
        title="账号设置"
        style="background-color: #fbfbfb"
        :left-arrow="true"
        @click-left="onClickLeft"
      />
    </div>
    <div class="content">
      <van-form @submit="onSubmit">
        <van-field
          label="头像"
          style="align-items: center"
          name="1"
          input-align="right"
          is-link
        >
          <template #input>
            <div @click="$refs.sss.chooseFile()" class="divImg flex">
              <van-image
                v-if="updateImg.length > 0"
                round
                style="width: 100%; height: 100%"
                :src="updateImg"
              />
              <van-image
                v-else
                round
                style="width: 100%; height: 100%"
                :src="$store.state.baseUrl + userInfo.user13"
              />
            </div>
            <van-uploader
              :before-read="beforeRead"
              :after-read="afterRead"
              v-show="false"
              max-count="1"
              ref="sss"
              multiple
            />
          </template>
        </van-field>
        <van-field
          label="姓名"
          name="2"
          v-model="userInfo.user04"
          input-align="right"
          placeholder="请输入姓名"
          is-link
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          label="性别"
          name="2"
          v-model="userInfo.user05"
          @click="sexShow = true"
          input-align="right"
          placeholder="请选择性别"
          is-link
          readonly
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          label="出生年月"
          name="2"
          readonly
          v-model="userInfo.user06"
          input-align="right"
          placeholder="请选择出生年月"
          @click="calendarShow = true"
          is-link
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          label="毕业院校"
          name="3"
          v-model="userInfo.user09"
          placeholder="请输入毕业院校"
          :rules="[{ required: true, message: '' }]"
          input-align="right"
          is-link
        />
        <van-field
          label="学历"
          name="3"
          readonly
          v-model="userInfo.user08"
          placeholder="请输入学历"
          :rules="[{ required: true, message: '' }]"
          input-align="right"
          @click="educationShow = true"
          is-link
        />
        <van-field
          label="职称"
          name="3"
          readonly
          v-model="userInfo.user07"
          @click="jobShow=true"
          placeholder="请输入职称"
          :rules="[{ required: true, message: '' }]"
          input-align="right"
          is-link
        />
        <van-field
          label="地址"
          name="3"
          v-model="userInfo.user10"
          readonly
          placeholder="请选择地址"
          @click="areaShow = true"
          :rules="[{ required: true, message: '' }]"
          input-align="right"
          is-link
        />
        <van-field
          label="邮箱"
          name="3"
          v-model="userInfo.user12"
          placeholder="请输入邮箱"
          :rules="[{ required: true, message: '' }]"
          input-align="right"
          is-link
        />
        <van-field
          label="手机号码"
          name="3"
          v-model="userInfo.user11"
          placeholder="请输入手机号"
          :rules="[{ required: true, message: '' }]"
          input-align="right"
          is-link
        />

        <div style="width: 90%; margin: 20px auto">
          <van-button type="info" style="width: 100%; margin: auto" round
            >保存信息</van-button
          >
        </div>
      </van-form>
    </div>
    <van-popup v-model="areaShow" position="bottom" :style="{ height: '50%' }">
      <van-area
        title="地址"
        @cancel="areaShow = false"
        @confirm="confirmArea"
        :area-list="areaList"
      />
    </van-popup>
    <van-popup v-model="sexShow" position="bottom" :style="{ height: '40%' }">
      <van-picker
        title="性别"
        show-toolbar
        value-key="name"
        :columns="columnsSex"
        @confirm="onConfirmSex"
        @cancel="sexShow = false"
      />
    </van-popup>
    <van-popup
      v-model="educationShow"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-picker
        title="学历"
        show-toolbar
        value-key="name"
        :columns="columnseducation"
        @confirm="onConfirmeducation"
        @cancel="educationShow = false"
      />
    </van-popup>
    <van-popup v-model="jobShow" position="bottom" :style="{ height: '40%' }">
      <van-picker
        title="职称"
        show-toolbar
        value-key="name"
        :columns="columnsJob"
        @confirm="onConfirmJob"
        @cancel="jobShow = false"
      />
    </van-popup>
    <van-calendar v-model="calendarShow" @confirm="onConfirmCalendar" />
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import {
  GetUserInfo,
  UpdatePerson,
  UploadUserImg,
  EditPersonalCenter,
} from "@/api";
export default {
  name: "MyMenu",
  components: {},
  data() {
    return {
      calendarShow: false,
      sexShow: false,
      jobShow: false,
      areaShow: false,
      educationShow: false,
      areaList: areaList,
      updateImg: "",
      file: "",
      columnsSex: [
        {
          id: 1,
          name: "男",
        },
        {
          id: 2,
          name: "女",
        },
      ],
      columnsJob: [
        {
          id: 1,
          name: "初级",
        },
        {
          id: 2,
          name: "中级",
        },
        {
          id: 3,
          name: "副高级",
        },
        {
          id: 4,
          name: "高级",
        },
      ],
      columnseducation: [
        {
          id: 1,
          name: "高中",
        },
        {
          id: 2,
          name: "大专",
        },
        {
          id: 3,
          name: "本科",
        },
        {
          id: 4,
          name: "研究生",
        },
      ],
      userInfo: {
        user02: "",
        user03: "",
        user04: "",
        user05: "",
        user06: "",
        user07: "",
        user08: "",
        user09: "",
        user10: "",
        user11: "",
        user12: "",
        user13: "",
        user14: "",
        user15: "",
        user16: "",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    onConfirmJob(v) {
      this.userInfo.user07 = v.name;
      this.jobShow = false;
    },
    onConfirmeducation(v) {
      this.userInfo.user08 = v.name;
      this.educationShow = false;
    },
    onConfirmSex(v) {
      this.userInfo.user05 = v.name;
      this.sexShow = false;
    },
    onConfirmCalendar(v) {
      this.userInfo.user06 = v.toLocaleDateString().replace(/\//g, "-");
      this.calendarShow = false;
    },
    confirmArea(v) {
      this.userInfo.user10 = v[0].name + "-" + v[1].name + "/" + v[2].name;
      this.areaShow = false;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    beforeRead(file) {
      if (file.type != "image/jpeg" && file.type != "image/png") {
        this.$Toast("请上传 jpg/png 格式图片");
        return false;
      }
      return true;
    },
    async afterRead(v) {
      this.updateImg = v.content;
      let formData = new FormData();
      if (v.file) {
        formData.append("uid", this.$store.state.uid);
        formData.append("file", v.file);
        let res = await UploadUserImg(formData);
        this.$CodeMessage(res, "修改头像成功");
      }
    },
    async getUserInfo() {
      let res = await GetUserInfo({
        params: {
          id: this.$store.state.uid,
        },
      });
      this.userInfo = res.Data;
      this.$store.state.userImg = res.Data.user08;
      this.$store.state.username = res.Data.user03;
    },
    async onSubmit(item) {
      let res = await EditPersonalCenter({
        model: JSON.stringify(this.userInfo),
      });
      res.Code > 0
        ? this.getUserInfo() && this.$Toast.success("修改成功")
        : this.$Toast.fail("修改失败");
    },
  },
  created() {
    this.getUserInfo();
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.menuDiv {
  background-color: white;
  padding: 30px;
}
.divImg {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
}
</style>